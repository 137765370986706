<template>
  <el-descriptions class="margin-top" title="" :column="3" :size="size" border>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        用户ID
      </template>
      {{ userInfo.userId }}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        用户头像
      </template>
      <div class="operate">
        <el-avatar class="avatar" :src="userInfo.avatar" alt="用户头像"
                   style="width: 50px; height: 50px; border-radius: 50%;"/>
        <el-button v-if="!this.editData.isEditingUserAvatar" type="text" @click="editData.isEditingUserAvatar = true">
          点击修改
        </el-button>
        <div v-else style="width: 100px">
          <el-upload
              class="upload-demo"
              :http-request="handleUpload"
              :on-change="handleUpload"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-error="handleError"
              :auto-upload="true"
              :limit="1"
              :on-exceed="handleExceed"
          >
            <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
            <div slot="tip" class="el-upload__tip">请选择要上传的文件（仅支持单个文件）</div>
            <!-- 省略手动上传按钮，因为我们将自动上传 -->
          </el-upload>
          <el-button type="text" @click="editData.isEditingUserAvatar=false">取消</el-button>
        </div>
      </div>

    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user-solid"></i>
        昵称
      </template>
      <div class="operate">
        {{ userInfo.nickName }}
        <el-button v-if="!this.editData.isEditingNickname" type="text" @click="editData.isEditingNickname = true">
          点击修改
        </el-button>
        <div v-else>
          <el-input style="width: 100px" v-model="formData.nickName" placeholder="请输入昵称"/>
          <el-button type="text" @click="updateUserInfo()">保存</el-button>
          <el-button type="text" @click="editData.isEditingNickname=false">取消</el-button>
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        用户名
      </template>
      {{ userInfo.userName }}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-time"></i>
        注册时间
      </template>
      <el-tag size="small">{{ userInfo.createTime }}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-time"></i>
        上次登录时间
      </template>
      <el-tag size="small">{{ userInfo.lastLoginTime }}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-male"></i>
        性别
      </template>
      <div class="operate">
        {{ userInfo.userGender }}
        <el-button v-if="!this.editData.isEditingUserGender" type="text" @click="editData.isEditingUserGender = true">
          点击修改
        </el-button>
        <div v-else>
          <el-input style="width: 100px" v-model="formData.userGender" placeholder="请输入昵称"/>
          <el-button type="text" @click="updateUserInfo()">保存</el-button>
          <el-button type="text" @click="editData.isEditingUserGender=false">取消</el-button>
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-view"></i>
        VIP
      </template>
      <a v-if="userInfo.vipFlag === 'Y'">尊敬的VIP</a>
      <a v-else>普通用户</a>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-table-lamp"></i>
        标签
      </template>
      <el-tag
          v-for="(label, index) in userInfo.userLabels"
          :key="index"
          :type="getTagType(index)"
          size="small">
        {{ label }}
      </el-tag>
    </el-descriptions-item>
  </el-descriptions>
</template>

<style>
.margin-top {
  margin-top: 200px;

  .operate {
    width: 100%;

    el-button {
      float: right;
    }
  }
}
</style>

<script>

import {uploadImg} from "@/api";
import {fetchUserInfo, updateUserInfo} from "@/api/userInfo";

export default {
  name: "app",
  components: {},
  data() {
    return {
      dialogVisible: false,
      formData: {
        userId: "",
        nickName: "",
        userGender: ""
      },
      editData: {
        isEditingNickname: false, // 控制是否正在编辑昵称
        isEditingUserGender: false, // 控制是否正在编辑昵称
        isEditingUserAvatar: false, // 控制是否正在编辑昵称
      },
      queryForm: {},
      friendsList: []
    }
  },
  computed: {
    userInfo() {
      let userInfo = this.$store.getters.userInfo;
      console.log("获取到用户信息", userInfo)
      return userInfo;
    },
  },
  methods: {
    // 随机颜色标签
    getTagType(index) {
      // const types = ['success', 'info', 'warning', 'danger'];
      // return types[Math.floor(Math.random() * types.length)];
      // 根据 index 或其他条件返回不同的类型  固定颜色
      switch (index % 4) {
        case 0:
          return 'success';
        case 1:
          return 'info';
        case 2:
          return 'warning';
        case 3:
          return 'danger';
        default:
          return 'info'; // 默认类型
      }
    },
    updateUserInfo(data) {
      this.formData.userId = this.userInfo.userId
      updateUserInfo(this.formData).then(res => {
        if (res.code === 200) {
          // 重置用户信息
          fetchUserInfo(this.formData).then(res => {
            if (res.code === 200 && res.data.length !== 0) {
              this.userInfo = res.data[0]
              // 假设你已经在某个Vue组件中，可以通过this.$store访问store
              this.$store.commit('SET_USER_INFO', res.data[0]);
              Object.keys(this.editData).forEach(key => {
                // 将每个键对应的值设置为false
                this.editData[key] = false;
              });
              this.$message.success("成功")
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleError(error, file, fileList) {
      // 上传失败后的处理逻辑
      // ...
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      });
      return
    },
    handleSuccess(response, file, fileList) {
      // 上传成功后的回调
      this.$message.success('文件上传成功');
      // 假设服务器返回了文件的信息，可以将其添加到 fileList 中，但这里由于只上传一个文件，并且已经自动添加，所以不需要额外操作
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$showMessage('上传文件大小不能超过 50MB!');
          return false;
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    handleUpload(item) {
      this.dialogVisible = true;
      const formData = new FormData();
      formData.append('file', item.raw);
      formData.append("filePath", "user/user_info/user_info_pictures/userId_" + this.userInfo.userId + "/");
      uploadImg(formData).then(res => {
        if (res.code === 200) {
          this.formData.userAvatar = res.data
          this.updateUserInfo(this.formData)
          this.dialogVisible = false;
        }
      })
    },
  }
}
</script>
